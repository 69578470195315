import $ from 'jquery';
import './pride-styles.scss';
import EventBusPrototype from 'EventBusPrototype';
const eventBus = new EventBusPrototype();
const node = $('body');
// Load page js
const pageNodes = node.find('[data-page]');
pageNodes.each((index, element) => {
  const nodeEle = $(element);
  const pageValue = nodeEle.attr('data-page');
  console.log(pageValue);
  if (pageValue === 'plain-content-page') {
    loadModules(`./templates/pages/content-page/content-page`, nodeEle)
  } else if (pageValue === 'b2b-product-page') {
    loadModules(`./templates/pages/content-page/content-page`, nodeEle)
  } else {
    loadModules(`./templates/pages/${pageValue}/${pageValue}`, nodeEle)
  }
});

// Load component js
const componentNodes = node.find('[data-component]');
componentNodes.each((index, element) => {
  const nodeEle = $(element);
  const componentValue = nodeEle.attr('data-component');
  console.log(componentValue);
  if (componentValue === 'content-central-accordion') {
    loadModules(`./templates/components/content-article/content-central-accordion`, nodeEle)
  } else if (componentValue === 'carousel-loader') {
    loadModules(`./templates/components/carousel/carousel-loader`, nodeEle)
  } else {
    loadModules(`./templates/components/${componentValue}/${componentValue}`, nodeEle);
  }
});

function loadModules(moduleName, nodeEle) {
  console.log(moduleName);
  return import(
  /* webpackInclude: /\.(js|jsx)$/ */
  /* webpackChunkName: "[request]" */
  /* webpackExclude: /src\/assets\/js/ */
  `${moduleName}`)
    .then(module => {
      const moduleInstance = module.default;
      const instance = new moduleInstance(nodeEle, {eventBus: eventBus});
      instance.build(); 
      if (moduleName === './templates/components/login-2022/login-2022') {
        instance.afterBuild();
      }
    })
    .catch(error => {
      console.error(`An error occurred while loading ${moduleName}`, error);
    });
}

// Lazy loading of Images

document.addEventListener('DOMContentLoaded',() => {
  const imageSelectors = {
    productCard: '.product-card__image > img',
    textAndImageVertical: '.cms-image-and-text__image',
    textAndImageHorizontal: '.cms-image-and-text__image > source, .cms-image-and-text__inner-image',
    imageHero: '.image-hero__background',
    imageAndColumnSplit: '.image-and-column-split__picture source, .image-and-column-split__image',
    marketingBanner5050: '.marketing-banner-50-50__background',
    contentArticleTeaser: '.content-central-article-teaser__article--has-image',
    newProductCard: '.cms-plan-card__image > img',
    newMagnoliaCard: '.cms-new-magnolia-card__image-container > img',
    newMagnoliaCardBackgroundImage: '.cms-new-magnolia-card--has-image',
    channelPacakgeCard: '.cms-channel-package-card__image',
  };

  Object.values(imageSelectors).forEach(selector => {
    const images = document.querySelectorAll(selector);
    images.forEach(image => {
      image.setAttribute('loading', 'lazy');
    });
  });
});

const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const image = entry.target;
      image.src = image.dataset.src;
      observer.unobserve(image);
    }
  });
});

document.querySelectorAll('img[data-src]').forEach(img => {
  observer.observe(img);
});
